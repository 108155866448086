<app-menu></app-menu>
<section class="bg-podcast">
    <!--Card 1-->
    <div class=" rounded shadow-md">  
       <div class=" row">
          <div class=" row mb-3 mt-5">
             
             <div class="mb-3 col-lg-6 ">
                 <img class="haltura logo" src="/assets/img/podcast/podcast_icon.png">
             </div> 
            
             <div class="col-lg-6  m-6">
                 <h3 class="font-bold text-left text-black mt-4">AGRO RURAL CAST</h3>  
                 <h5 class="text-black text-left ">
                     Escolha o audio que gostaria de ouvir em nossa galeria:
                 </h5>
             </div>
         </div>
         <div class="justify-center">
             <div class="bg-lime-50 p-5 shadow rounded" >
             <!-- <iframe [src]="urlSafe.podCastUrl" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe> -->
                 <iframe src="https://open.spotify.com/embed/episode/7K26bCwRpJmUYEMlw8Wilx" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                 <iframe src="https://open.spotify.com/embed/episode/5xCDn9RVb8LzMf2134S2Tn" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                 <iframe src="https://open.spotify.com/embed/episode/1D2gsj9x4CysAw46sS8O2L" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                 <iframe src="https://open.spotify.com/embed/show/5d1dIq6Zt4KzSa9YLnh1J5" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                 <iframe src="https://open.spotify.com/embed/episode/5NccJAV53PPvBLNQOUvPMw" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>            
                 <iframe src="https://open.spotify.com/embed/episode/1bvaCKyZX2LXbmRdSNrjB4" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                 <iframe src="https://open.spotify.com/embed/episode/1j9fG21HW2ii7SWa0kzlEO" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
             </div>        
         </div>
       </div>    
     </div>
 </section>
 <app-footer></app-footer>
 