
<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-light">
    <div class="container-fluid">
      <a class="navbar-brand m-2" routerLink="/principal"><img class="imagem" src="/assets/img/menu/logomenu.png" alt=""/></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse ml-8 " id="navbarNavDarkDropdown">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link " routerLink="/gravado" id="navbarDarkDropdownMenuLink">
                GRAVADO
            </a>
        </li>
         <li class="nav-item dropdown">
            <a class="nav-link " routerLink="/podcast" id="navbarDarkDropdownMenuLink">
                PODCAST
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link " routerLink="/programacao" id="navbarDarkDropdownMenuLink">
                PROGRAMAÇÃO
            </a>
          </li>

        </ul>
      </div>
    </div>
  </nav> -->

<!-- <section>
  <div class="container">
    <div class="row">
      <div class="col-sm">col</div>
      <div class="col-sm">col</div>
      <div class="col-sm">col</div>
      <div class="col-sm">col</div>
    </div>
  </div>  
</section>   -->
<div class="bg-light">
  <div class="row text-center">
    <div class="col-sm">
      <a class="navbar-brand m-2" routerLink="/principal"><img class="imagem" src="/assets/img/menu/logo.png" alt=""/></a>
    </div>
    <!-- <div class="col-sm"><a class="nav-link" scroll="intro-wrap" ><h5 class="font-weight-bold text1 pt-3 ">AO VIVO</h5></a></div> -->
    <div class="col-sm  "><a class="nav-link" routerLink="/podcast" ><h5 class="font-weight-bold text1 pt-3 ">PODCAST</h5></a></div>
    <div class="col-sm  "><a class="nav-link" routerLink="/gravado" ><h5 class="font-weight-bold text1 pt-3 ">GRAVADO</h5></a></div>
    <div class="col-sm  "><a class="nav-link" routerLink="/programacao" ><h5 class="font-weight-bold text1 pt-3 ">PROGRAMAÇÃO</h5></a></div>
  </div>
</div>

<!-- <section class="bg-light">
        <div>
          <a class="navbar-brand m-2" routerLink="/principal"><img class="imagem" src="/assets/img/menu/logomenu.png" alt=""/></a>
        </div>
        <div class="text-center pt-4 bg-light"  id="div1">
         <h4 class="font-weight-bold text1 espacamento">AO VIVO</h4>   
        </div>
        <div class="text-center pt-4 bg-light" id="div2">
         <h4 class="font-weight-bold text1 espacamento">PODCAST</h4>  
        </div>
        <div class="text-center pt-4 bg-light" id="div3">
         <h4 class="font-weight-bold text1 espacamento marg1">GRAVADO</h4> 
        </div>
        <div class="text-center pt-4 bg-light" id="div4">
         <h4 class="font-weight-bold text1 espacamento marg">PROGRAMAÇÃO</h4>  
        </div>
  </section>  -->
