<section class=" back">
    
    <div class="container ">
       <div class="row">

        <div class="flex text-center col-sm ">
            <!-- Column -->
            <div class="my-2 px-2 bloco ">
    
                <!-- Article -->
                <article class="overflow-hidden rounded ">
    
                    <a href="#" routerLink="/programacao">
                        <img  class="block img-fluid " src="/assets/img/cronograma/programacao.jpeg">
                    </a>
                </article>
                <!-- END Article -->
    
            </div>
            <!-- END Column -->
        </div>

        <div class="flex text-center col-sm ">
            <!-- Column -->
            <div class="bloco my-2 px-2 ">
    
                <!-- Article -->
                <article class="overflow-hidden rounded">
    
                    <a href="#" routerLink="/podcast">
                        <img class="block img-fluid" src="/assets/img/cronograma/podcast.jpeg">
                    </a>
                </article>
                <!-- END Article -->
    
            </div>
            <!-- END Column -->
        </div>

        <div class="flex text-center col-sm ">
            <!-- Column -->
            <div class=" bloco my-2 px-2 mb-4">
    
                <!-- Article -->
                <article class="overflow-hidden rounded">
    
                    <a href="#" routerLink="/gravado">
                        <img  class="block img-fluid " src="/assets/img/cronograma/gravado.jpeg">
                    </a>
                </article>
                <!-- END Article -->
    
            </div>
            <!-- END Column -->
        </div>
      </div>
    </div>       
</section>


