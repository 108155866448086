<section class="">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="imagemIcon">
            <a style='color: rgb(6, 36, 6)' href="https://www.google.com.br/maps/place/Parque+de+Exposi%C3%A7%C3%B5es+Jo%C3%A3o+Alencar+de+Athayde/@-16.7120459,-43.8532486,17z/data=!4m12!1m6!3m5!1s0x754ab46603101fd:0xaac93e0354a623d9!2sParque+de+Exposi%C3%A7%C3%B5es+Jo%C3%A3o+Alencar+de+Athayde!8m2!3d-16.7120392!4d-43.851032!3m4!1s0x754ab46603101fd:0xaac93e0354a623d9!8m2!3d-16.7120392!4d-43.851032">
              <i class="sIcon  bi bi-geo-alt-fill"></i>
            </a>
          </div>
              <h5 class="fw-bold posicao">PARQUE DE EXPOSIÇÕES</h5>  
              <h5 class="posicao">  JOÃO ALENCAR ATHAIDE  </h5>
        </div>
        <div class="col-sm">
          <div class="alinhamentoface">
            <a style='color: rgb(6, 36, 6)' href=" https://pt-br.facebook.com/sociedaderural">
              <i class="imagem3 bi bi-facebook"></i>
            </a>
            <!-- <img class="imagem3 d-inline-block " src="/assets/img/Face.png"/> -->
          </div>
          <div class="alinhamento">
            <a style='color: rgb(6, 36, 6)' href="https://www.instagram.com/sociedaderural" >
              <i class="imagem4 bi bi-instagram"></i>
            </a>   
          </div>  
        </div>
        <div class="col-sm">
          <img class="imagemx" src="/assets/img/expRealizacao.webp"/>
        </div>
      </div>
    </div>
  
  </section>
