<app-menu></app-menu>
<section class="back" id="content03-e">
    
    <div class="container">
        <div class="row justify-content-center">
            <div class="counter-container col-md-12 col-lg-">
                <h2 class="mbr-fonts-style mb-5 display-7 font-bold text-center mt-5 ">PROGRAMAÇÃO</h2>
                <!-- <h4 class="mbr-section-subtitle mbr-fonts-style mb-5 display-7 text-center">PALESTRAS EXPOMONTES</h4> -->
                <div class="row icon mb-5">
                    <div class="shadow bg-lime-900 rounded-md mb-5 pt-1">
                        <h3 class=" text-center mbr-text mbr-fonts-style display-7 font-bold ">
                            Dia 04</h3>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            16:00h<br></h4>
                    </div>
                    <div class="col-lg-10">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            Controle de qualidade e certificação do queijo artesanal - (ON-LINE)&nbsp;</h4>
                    </div>
                </div>


                <div class="row icon mb-5">
                    <div class="shadow bg-lime-900 rounded-md mb-5 pt-1 ">
                        <h3 class=" mbr-text mbr-fonts-style display-7 font-bold text-center">
                            Dia 05</h3>
                    </div>
                    <div class="col-lg-2 mb-2">
                        <h4 class="mbr-text mbr-fonts-style display-7 ">
                            09:00h  <br></h4>
                    </div>
                    <div class="col-lg-10 ">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            Seminário Saneamento - Emater (presencial) </h4>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            13:00h <br></h4>
                    </div>
                    <div class="col-lg-10">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            Dia De Campo (Parque de Exposição) </h4>
                    </div>
                </div>
                <div class="row icon mb-5">
                    <div class="shadow bg-lime-900 rounded-md mb-5 pt-1">
                        <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
                            Dia 06</h3>
                    </div>
                    <div class="col-lg-2 mb-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            08:00 às 12:00h<br></h4>
                    </div>
                    <div class="col-lg-10 mb-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            Tema: NEProTEC / Pulgão do sorgo e cigarrinha do milho: “Conhecer para controlar”. </h4>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            15:00h<br></h4>
                    </div>
                    <div class="col-lg-10 mb-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            Tema: Incêndios florestais em áreas rurais no Norte de Minas Corpo Bombeiro (on-line). </h4>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            16:00h<br></h4>
                    </div>
                    <div class="col-lg-10 mb-3">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            Workshop cerveja artesanal (on-line) produção de lúpulo. </h4>
                    </div>
                </div>
                <div class="row icon mb-5">
                    <div class="shadow bg-lime-900 rounded-md mb-5 pt-1">
                        <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
                            Dia 07</h3>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            09:00h<br></h4>
                    </div>
                    <div class="col-lg-10 mb-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            Adubação e manejo de pastagens (presencial). </h4>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            10:00h<br></h4>
                    </div>
                    <div class="col-lg-10 mb-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            Cenário e expectativas para a pecuária de corte e insumos (presencial). </h4>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            14:00h<br></h4>
                    </div>
                    <div class="col-lg-10 mb-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            A importância das atividades de cria e de recria de bovinos de corte, para a produção do boi gordo (“BOI CHINA”). (on-line). </h4>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            15:00h<br></h4>
                    </div>
                    <div class="col-lg-10 mb-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                          Mercado da pecuária bovina de corte: A importância do referêncial de preços do boi gordo e do bezerro de corte Nelore comercial - CEPEA (on-line) </h4>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            16:00h<br></h4>
                    </div>
                    <div class="col-lg-10">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            - ILPF (on-line) 
                        </h4>
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            - Carbono Neutro (on-line) 
                        </h4>
                        <h4 class="mbr-text mbr-fonts-style display-7">
                            - Balanço carbono na pecuária (on-line)
                        </h4>
                    </div>
                </div>
                <div class="row icon mb-5">
                    <div class="shadow bg-lime-900 rounded-md mb-5 pt-1">
                        <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
                            Dia 08</h3>
                    </div>
                    <div class="col-lg-2">
                        <h4 class="mbr-text mbr-fonts-style display-7">
                           15:00h <br></h4>
                    </div>
                    <div class="col-lg-10">
                        <h4 class="mbr-text mbr-fonts-style display-7 mb-5">
                            Princípios básicos do treinamento dos equinos (on-line). </h4> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>