<app-menu></app-menu>
<section id="intro-wrap" class="back ">
  <div class="">
    <app-banner></app-banner>
    <!-- <div class="row">
      <div class="col">
        <img class="imagem" src="/assets/img/expSuperior.webp">
      </div>
      <div class="col">
          <img class="imagem1" src="/assets/img/expoMeio.png"/>
      </div>  
    </div> -->
</div>
  <div class="container video">
    <div class=" section-header mt-5 ">
        <h4 class="font-weight-bold text-32 text-center mb-4">EXPOMONTES AO VIVO</h4>
    </div>
    <div class="">
        
        <div class="d-flex justify-content-center mb-1">
            <!-- <youtube-player videoId="dQw4w9WgXcQ"></youtube-player> -->
            <iframe width="560" height="315" src="https://www.youtube.com/embed/2-kI3zf_IlI" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
  </div>

  
    <!-- <div class="row text-center mt-5">
    
      <div class="col-sm  "><button class="btn rounded text2 p-3" routerLink="/podcast"> PODCAST</button> </div>
      <div class="col-sm  "><button class="btn rounded text2 p-3"routerLink="/gravado"> GRAVADO </button></div>
      <div class="col-sm  "><button class="btn rounded text2  p-3" outerLink="/programacao">PROGRAMAÇÃO</button></div>
    </div>
  -->

</section>
<app-cronograma></app-cronograma>
<app-footer></app-footer>
