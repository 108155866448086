<app-menu></app-menu>
<section class="back ">
 
  <div class=" ">
    <div class=" section-header ">
        <h2 class="font-weight-bold text-42 text-center titulo font-bold">Expomontes Gravado</h2>
    </div>


    <div class="row row d-flex justify-content-center " style="padding: 2rem;">
        <div class="shadow bg-lime-900 rounded-md mb-3  pt-1">
            <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
                Dia 31</h3>
        </div>

        <div class="col-md-8 col-sm-4">
          <div class="card card-block rounded-md mt-3">
            <iframe width="580" class="card-img-top" height="285" src="https://www.youtube.com/embed/ci1nkmy69mU" frameborder="0" allowfullscreen></iframe>
            <h5 class="card-title  p-3">Festa do Curral 2022.</h5>
          </div>
        </div>
    </div>

    <div class="row row d-flex justify-content-center " style="padding: 2rem;">
        <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
            <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
                Dia 01</h3>
        </div>

        <div class="col-md-5 col-sm-4">
          <div class="card card-block rounded-md mt-3">
            <iframe width="580" class="card-img-top" height="185" src="https://www.youtube.com/embed/gey3_4AVaG8" frameborder="0" allowfullscreen></iframe>
            <h5 class="card-title  p-3">Abertura Oficial1 - Expomontes 2022</h5>
          </div>
        </div>

        <div class="col-md-5 col-sm-4">
          <div class="card card-block rounded-md mt-3">
            <iframe width="580" class="card-img-top" height="185" src="https://www.youtube.com/embed/JxKeygqM7BY" frameborder="0" allowfullscreen></iframe>
            <h5 class="card-title  p-3">Abertura Oficial2 -  Expomontes 2022</h5>
          </div>
        </div>
    </div>

    <div class="row row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
          <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
              Dia 02</h3>
      </div>

      <div class="col-md-8 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="580" class="card-img-top" height="285" src="https://www.youtube.com/embed/JHGweB4hK3w" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">E a sanfona chorou para comemorar o 2º dia de sucesso da Expomontes</h5>
        </div>
      </div>

    </div>
    <div class="row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
          <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
              Dia 03</h3>
      </div>

      <div class="col-md-6 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="580" class="card-img-top" height="185" src="https://www.youtube.com/embed/cOWyX-xktR4" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Team Penning</h5>
        </div>
      </div>

      <div class="col-md-6 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="560" class="card-img-top" height="185" src="https://www.youtube.com/embed/b8_6XoeRQm4" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Na batida do martelo! O tradicional leilão Futuro Novilho Precoce</h5>
        </div>
      </div>
      
    </div>

    <div class="row row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
          <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
              Dia 04</h3>
      </div>

      <div class="col-md-4 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="560" class="card-img-top" height="185" src="https://www.youtube.com/embed/8OFoiH1ZgLM" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Controle de qualidade e certificação do queijo artesanal</h5>
        </div>
      </div>  

      <div class="col-md-4 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="560" class="card-img-top" height="185" src="https://www.youtube.com/embed/1SvbyOAiCus" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Ferinha da Agricultura Familiar</h5>
        </div>
      </div>  

      <div class="col-md-4 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="560" class="card-img-top" height="215" src="https://www.youtube.com/embed/t8k5OC_g9Ac" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">As grandes atrações da Expomontes</h5>
        </div>
      </div> 
   
    </div>

    <div class="row row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
          <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
              Dia 05</h3>
      </div>

      <div class="col-md-4 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="560" class="card-img-top" height="185" src="https://www.youtube.com/embed/jpfD9VYQf9I" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Show Padre Ivan</h5>
        </div>
      </div>  
      
      <div class="col-md-4 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="560" class="card-img-top" height="185" src="https://www.youtube.com/embed/f3IqWMJF2HE" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">O Sicoob Credinor</h5>
        </div>
      </div> 
      
      <div class="col-md-4 col-sm-4">
        <div class="card card-block rounded-md mt-3">
          <iframe width="560" class="card-img-top" height="185" src="https://www.youtube.com/embed/slyPWui2Vao" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Dia de Campo</h5>
        </div>
      </div> 
    </div>
  
    <div class="row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
        <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
            Dia 06</h3>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/I1DIal1vDjU" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Por trás do sucesso da Expomontes</h5>
        </div>
      </div>  
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/gETHLJj5teo" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">A fazendinha da Expomontes</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/_NFIFarYP2w" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Whorkshop Cerveja Artesanal</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/AhfY3_bTsFg" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">16° leilão OMJ fez sucesso</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/YfNo4k4AE0I" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title p-3">Aline Barros</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="auto" class="card-img-top" height="115" src="https://www.youtube.com/embed/Y4ubLQd5RbY" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title p-3">Entrevista - Incêndios Florestais no Norte de Minas</h5>
        </div>
      </div>    
    </div>

    <div class="row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
        <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
            Dia 07</h3>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/NzNb-EAGTlg" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Julgamento Girolando - Expomontes 2022</h5>
        </div>
      </div>  
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/vJRwYEUDCRQ" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Integração lavoura pecuária floresta</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/1xwhY1RYsNs" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Mercado da pecuária bovina de corte</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/PwNW-Z5kNWs" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">A importância das atividades de cria e recria para produção de boi gordo padrão</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/qcFzK4mVuAk" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title p-3">Gustavo Lima - Ao vivo - Expomontes</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="auto" class="card-img-top" height="115" src="https://www.youtube.com/embed/A97mTrAD4SM" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title p-3">Julgamento Morfológico da Raça Girolando</h5>
        </div>
      </div>    
    </div>

    <div class="row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
        <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
            Dia 08</h3>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/2-kI3zf_IlI&t=41s" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Expomontes noite do dia 08</h5>
        </div>
      </div> 
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/0QB3Mjn3bFY" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Show João Gomes</h5>
        </div>
      </div> 
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/BwoKqmCFcfg" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Agricultura Irrigada</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/5PvANLkagKk" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Princípios básicos do treinamento dos equinos</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="115" src="https://www.youtube.com/embed/jW_F5Syhybs" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title p-3">Bastidores Gustavo Lima</h5>
        </div>
      </div>   
      <div class="col-md-2 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="auto" class="card-img-top" height="115" src="https://www.youtube.com/embed/h3lRZoDgZUU" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title p-3">Conheça como tudo acontece nos bastidores</h5>
        </div>
      </div>    
    </div>


    <div class="row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
        <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
            Dia 09</h3>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="175" src="https://www.youtube.com/embed/eNnBQM_86Oc" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Estande do SEBRAE</h5>
        </div>
      </div>   
      <div class="col-md-3 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="175" src="https://www.youtube.com/embed/T-g9OP887BI" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Dilsinho - Dennis DJ - Léo Santana</h5>
        </div>
      </div>   
      <div class="col-md-3 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="175" src="https://www.youtube.com/embed/eROjb-b1ha8" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title p-3">Laura Nobre</h5>
        </div>
      </div>   
      <div class="col-md-3 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="auto" class="card-img-top" height="175" src="https://www.youtube.com/embed/WZWqQSW6EFA" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title p-3">9 de Julho de 2022</h5>
        </div>
      </div>    
    </div>

    <div class="row d-flex justify-content-center " style="padding: 2rem;">
      <div class="shadow bg-lime-900 rounded-md mb-3 pt-1">
        <h3 class="mbr-text mbr-fonts-style display-7 font-bold text-center">
            Dia 10</h3>
      </div>
      <div class="col-md-5 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="175" src="https://www.youtube.com/embed/ot-Hdz_g_Nc" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Último dia de Exposições 2022</h5>
        </div>
      </div>   
      <div class="col-md-5 col-sm-6">
        <div class="card card-block mt-3">
          <iframe width="560" class="card-img-top" height="175" src="https://www.youtube.com/embed/4uOfYg6yU40" frameborder="0" allowfullscreen></iframe>
          <h5 class="card-title  p-3">Expomontes 2022 Xand Avião e Zé Vaqueiro</h5>
        </div>
      </div>     
    </div>
  </div>
</section>
<app-footer></app-footer>





   
    
  