import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CronogramaComponent } from './cronograma/cronograma.component';
import { GravadoComponent } from './gravado/gravado.component';
import { PodcastComponent } from './podcast/podcast.component';
import { PrincipalComponent } from './principal/principal.component';
import { ProgramacaoComponent } from './programacao/programacao.component';

const routes: Routes = [ 
  { path: '', component: PrincipalComponent},
  {path: 'gravado', component: GravadoComponent},
  {path: 'principal', component: PrincipalComponent},
  {path: 'programacao', component: ProgramacaoComponent},
  {path: 'podcast', component: PodcastComponent},
  {path: 'cronograma', component: CronogramaComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
